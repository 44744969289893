<template>
  <div>
    <draggable
      class="list-group w-full"
      handle=".handle"
      tag="ul"
      v-model="custom_data.options"
      v-bind="dragOptions"
      @start="drag = true"
      @end="dragEnd"
    >
      <div
        class="border w-full bg-white my-3"
        v-for="(option, i) in custom_data.options"
        :key="`${i}-option`"
      >
        <div class="flex justify-between items-center">
          <div class="bg-gray-100 h-full px-4 py-3 border-r">
            <i
              class="fa fa-align-justify handle text-my-2 px-3-600 cursor-move"
            />
          </div>
          <div class="w-10/12 px-2">
            <input
              class="focus:outline-none px-4 py-2 w-11/12"
              :placeholder="$t('option')"
              @input="$emit('markEdited')"
              v-model="custom_data.options[i].value"
              :class="{
                'pointer-events-none': custom_data.type == 'yesno'
              }"
            />
          </div>
          <i
            class="fas fa-minus-square"
            @click="removeOption(i)"
            v-if="custom_data.type != 'yesno'"
          />
          <!-- <i class="fas fa-ellipsis-v"></i> -->
          <div class="font-semibold mr-3" @click="toggleOptionTrigger(i)">
            <i v-if="option.hasTrigger" class="fas fa-bell text-teal-500" />
            <i v-else class="fas fa-bell-slash text-rose-500" />
            <!-- {{ $t("trigger") }} -->
          </div>
        </div>
        <div v-if="custom_data.showSolutions" class="p-2 border-t pl-12">
          <h3 class="font-semibold text-xs">{{ $t("solution") }}</h3>
          <div
            v-for="(solution, solutionIndex) in option.solutions"
            :key="`solution-${solutionIndex}`"
          >
            <input
              class="border-b focus:outline-none px-4 py-2 w-3/4"
              :placeholder="$t('name')"
              v-model="solution.name"
              @input="$emit('markEdited')"
            />
            <i
              class="fas fa-minus-square"
              @click="removeSolution(i, solutionIndex)"
            />
          </div>
          <span class="text-xs font-semibold" @click="addSolution(i)">
            <i class="fas fa-plus-square ml-3 mt-2" />
            {{ $t("add solution") }}
          </span>
        </div>
        <div
          class="bg-coolGray-100 px-4 py-2 border-t"
          v-if="option.hasTrigger"
        >
          <i class="fas fa-bell text-teal-500" />
          <label class="mx-3" for="">{{ $t("slack") }}</label>
          <input
            v-model="option.trigger.slack"
            class="px-3 py-1 w-8/12"
            @change="$emit('markEdited')"
          />
        </div>
      </div>
    </draggable>
    <span
      class="text-xs font-semibold"
      @click="addOption"
      v-if="custom_data.type != 'yesno'"
    >
      <i class="fas fa-plus-square ml-3 mt-2" />
      {{ $t("add option") }}
    </span>
  </div>
</template>

<script>
import draggable from "vuedraggable";
export default {
  name: "RadioCheckBox",
  components: {
    draggable
  },
  props: {
    custom_data: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      drag: false,
      dragOptions: {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      }
    };
  },
  methods: {
    dragEnd() {
      this.drag = false;
      this.$emit("markEdited");
    },

    removeOption(i) {
      this.custom_data.options.splice(i, 1);
    },
    addSolution(optionIndex) {
      this.$emit("markEdited");
      this.custom_data.options[optionIndex].solutions.push({
        name: "",
        description: ""
      });
    },
    removeSolution(optionIndex, solutionIndex) {
      this.$emit("markEdited");
      this.custom_data.options[optionIndex].solutions.splice(solutionIndex, 1);
    },
    addOption() {
      this.$emit("markEdited");
      this.custom_data.options.push({
        value: "",
        solutions: [],
        hasTrigger: false,
        triggers: { slack: "" }
      });
    },
    toggleOptionTrigger(index) {
      this.$emit("markEdited");
      const trigger = this.custom_data.options?.[index]?.hasTrigger
        ? !this.custom_data.options?.[index]?.hasTrigger
        : true;
      this.$set(this.custom_data.options[index], "hasTrigger", trigger);
      if (!this.custom_data.options?.[index]?.trigger) {
        this.$set(this.custom_data.options[index], "trigger", {
          slack: ""
        });
      }
      // this.custom_data.options[index].hasTrigger = !this.custom_data.options[index]
      //   .hasTrigger;
    }
  }
};
</script>
