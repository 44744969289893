<template>
  <div>
    <h3
      class="mx-4 pt-4 cursor-pointer flex justify-between items-center font-semibold text-blueGray-500 uppercase text-xs"
    >
      {{ $t(title) }}
    </h3>
    <div class="mx-4">
      <el-cascader
        @change="$emit('change')"
        class="w-full"
        v-model="vals"
        :options="options"
        :props="{ multiple: true }"
        clearable
        filterable
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "CascadeSelectorAssetAssign",
  props: {
    title: {
      type: String,
      require: true
    },
    options: {
      type: Array,
      require: true
    },
    cas: {
      type: Array,
      require: true
    }
  },
  computed: {
    vals: {
      set(val) {
        this.$emit("input", val);
      },
      get() {
        return this.cas;
      }
    }
  }
};
</script>
