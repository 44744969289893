<template>
  <div class="w-full mt-2">
    <button
      class="bg-blue-600 px-4 py-2 text-blue-50 rounded"
      @click="uploadClicked"
    >
      {{ $t("attach file(s)") }}
    </button>
    <input
      id="uploadFile"
      type="file"
      ref="uploadFile"
      accept="media_type"
      @change="fileChange"
      multiple
      hidden
    />
    <div class="grid gap-4 py-2 grid-cols-1 md:grid-cols-4 lg:grid-cols-5">
      <div class="relative" v-for="media in medias" :key="media.id">
        <img class="w-full rounded" :src="media.path" />
        <i
          class="fas fa-times-circle absolute -top-2 -right-2 bg-white rounded-full p-1 border cursor-pointer"
          @click="deleteMedia(media.id)"
        />
      </div>
      <div
        class="relative"
        v-for="(file, index) in item.files"
        :key="file.name"
      >
        <img class="rounded" :src="getBlobUrl(file)" />
        <i
          class="fas fa-times-circle absolute -top-2 -right-2 bg-white rounded-full p-1 border cursor-pointer"
          @click="removeTmpMedia(index)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UploadMedia",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    medias() {
      return this.item?.medias ? this.item?.medias : [];
    }
  },
  methods: {
    getBlobUrl(file) {
      return URL.createObjectURL(file);
    },
    uploadClicked() {
      this.$refs.uploadFile.click();
    },
    fileChange() {
      this.$emit("markEdited");
      this.$set(this.item, "files", this.$refs.uploadFile.files);
    },
    removeTmpMedia(index) {
      this.item.files = Array.from(this.item.files).filter(
        (_, i) => i != index
      );
    },
    deleteMedia(mediaId) {
      this.$store.dispatch("checkListItems/deleteCheckListMedia", {
        id: this.item.id,
        mediaId,
        checklistId: this.$store.state.checkLists.checkList.id
      });
    }
  }
};
</script>
