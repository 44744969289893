<template>
  <div class="px-4 pb-2 border-b">
    <h3
      class="text-xs font-semibold uppercase text-gray-500 mb-3 mt-4 flex justify-between items-center"
    >
      <span>
        {{ $t("schedule") }}
      </span>

      <div class="border border-blue-600 rounded flex">
        <div
          class="px-2 py-1 border-blue-600 hover:bg-blue-600 hover:text-blue-50 cursor-pointer"
          v-for="(item, index) in options"
          :key="item"
          @click="scheduleType = item"
          :class="{
            'bg-blue-700 text-blue-50': scheduleType == item,
            'border-l-0': index == 0,
            'border-l': index != 0
          }"
        >
          {{ item }}
        </div>
      </div>
    </h3>
    <div class="px-2 flex justify-between">
      <el-date-picker
        v-show="hasEnd"
        v-model="startDateVal"
        type="date"
        placeholder="Select date and time"
        :picker-options="pickerOptions"
      />
      <el-date-picker
        v-show="!hasEnd"
        v-model="endDateVal"
        type="daterange"
        placeholder="Select date and time"
        :picker-options="pickerOptions"
      />
      <el-time-picker
        value-format="HH:mm"
        format="HH:mm"
        placeholder="End time"
        v-model="timeVal"
        :picker-options="{
          format: 'HH:mm'
        }"
      />
    </div>
    <div class="flex items-center py-2 px-3" v-if="scheduleType == 'weekly'">
      <div
        class="mr-2 border border-blue-600 rounded px-2 cursor-pointer"
        :class="{
          'bg-blue-600 text-blue-50': selectedWeekDay == day.value,
          'text-blue-500': selectedWeekDay != day.value
        }"
        v-for="day in weekly"
        :key="day.label"
        @click="selectWeekDay(day)"
      >
        {{ day.label }}
      </div>
    </div>
    <div class="flex items-center py-2 px-3" v-if="scheduleType == 'monthly'">
      <span class="mr-2"> {{ $t("occuring day") }}: </span>
      <el-select v-model="selectedMonthDay" placeholder="Select">
        <el-option
          v-for="day in monthly"
          :key="day"
          :label="day"
          :value="day"
        />
      </el-select>
    </div>
    <!-- <div class="flex justify-between items-center py-2"> -->
    <div
      class="px-3 py-1"
      @click="hasEnd = !hasEnd"
      v-if="scheduleType != 'one-time'"
    >
      <i
        class=""
        :class="{
          'fas fa-check-square': !hasEnd,
          'far fa-square': hasEnd
        }"
      />
      {{ $t("has end date") }}
    </div>
    <div class="flex justify-end mt-2">
      <button
        class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs ml-4 px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        type="button"
        @click="handleSaveSchedule"
      >
        {{ $t("save") }}
      </button>
    </div>
    <!-- </div> -->
    <div class="pt-3">
      <div class="font-semibold text-xs uppercase">
        {{ $t("scheduled") }}
      </div>
      <h4
        class="text-xs text-rose-600 font-semibold"
        v-if="schedules.length == 0"
      >
        {{ $t("no scheudles yet") }}
      </h4>
      <div
        v-for="(schedule, sIndex) in schedules"
        :key="schedule.id"
        class="px-2 py-1"
        :class="{
          'bg-gray-50': sIndex % 2 == 0
        }"
      >
        <div class="relative">
          {{ getDateOnly(schedule.start_date) }}
          {{ schedule.scheduled_at }}
          <div class="text-xs">
            {{ schedule.type }}
          </div>
          <el-popconfirm
            :title="$t('Are you sure to delete this?')"
            @confirm="removeSchedule(schedule)"
          >
            <i
              slot="reference"
              class="far fa-trash-alt text-rose-500 absolute text-sm right-2 top-1"
            />
          </el-popconfirm>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import formatting from "../../mixins/formatting";
export default {
  name: "ScheduleChecklist",
  mixins: [formatting],
  props: {
    schedules: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      hasEnd: true,
      startDateVal: "",
      endDateVal: "",
      timeVal: "",
      scheduleType: "one-time",
      options: ["one-time", "daily", "weekly", "monthly"],
      selectedWeekDay: null,
      selectedMonthDay: null,
      monthly: [...Array(31).keys()].map(day => day + 1),
      weekly: [
        { label: "mo", value: 1 },
        { label: "tu", value: 2 },
        { label: "we", value: 3 },
        { label: "th", value: 4 },
        { label: "fr", value: 5 },
        { label: "sa", value: 6 },
        { label: "su", value: 7 }
      ],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < moment(Date.now()).startOf("day");
        }
      }
    };
  },
  watch: {
    scheduleType(type) {
      if (type == "one-time") {
        this.hasEnd = true;
      }
    }
  },
  methods: {
    selectWeekDay(day) {
      this.selectedWeekDay =
        this.selectedWeekDay == day.value ? null : day.value;
      // if (this.selectedWeekDay == day.value) {
      //   this.selectedWeekDay = null;
      // } else {
      //   this.selectedWeekDay = day.value;
      // }
    },
    removeSchedule(schedule) {
      this.$store.dispatch("checkLists/deleteSchedule", { schedule });
    },
    handleSaveSchedule() {
      const type =
        this.scheduleType == "one-time" ? "single" : this.scheduleType;

      const check_list_schedule = {
        type,
        scheduled_at: this.timeVal,
        occur_at: "",
        checklist_id: this.$route.params.id
      };
      if (this.hasEnd) {
        check_list_schedule.start_date = moment(this.startDateVal).format(
          "YYYY-MM-DDT00:00:00"
        );
      } else {
        check_list_schedule.start_date = moment(this.endDateVal[0]).format(
          "YYYY-MM-DDT00:00:00"
        );
        check_list_schedule.end_date = moment(this.endDateVal[1]).format(
          "YYYY-MM-DDT00:00:00"
        );
      }
      if (this.scheduleType == "weekly") {
        check_list_schedule.occur_at = this.selectedWeekDay;
      }
      if (this.scheduleType == "monthly") {
        check_list_schedule.occur_at = this.selectedMonthDay;
      }
      this.$store.dispatch("checkLists/createSchedule", {
        check_list_schedule
      });
      // this.handleSave(,
      //   cb: () => this.$emit("cancelNameChange")
      // });
    }
  },
  created() {
    this.checkListName = this.name;
    this.checkListType = this.type_id;
  }
};
</script>
