<template>
  <div class="checklist-detail md:-mt-16 w-full md:pb-14 md:flex relative">
    <div class="flex w-full">
      <div>
        <div
          class="w-52 bg-white border-r overflow-y-scroll"
          style="height: calc(100vh - 128px)"
        >
          <div class="px-7 py-5 border-b">
            <span
              class="flex items-center font-semibold"
              :class="{
                'text-teal-500': checkList.published,
                'text-rose-500': !checkList.published
              }"
            >
              <span
                class="inline-block w-3 h-3 rounded-full mr-2"
                :class="{
                  'bg-teal-500': checkList.published,
                  'bg-rose-500': !checkList.published
                }"
              />
              <span v-if="checkList.published">{{ $t("active") }}</span>
              <span v-if="!checkList.published">{{ $t("inactive") }}</span>
            </span>
          </div>
          <div class="sticky top-32">
            <draggable
              class="dragArea list-group grid"
              :list="actions"
              :group="{ name: 'action', pull: 'clone', put: false }"
              :move="onMove"
            >
              <div
                v-for="action in actions"
                class="cursor-pointer p-2 flex items-center px-3 m-2 mx-5 bg-indigo-50 font-semibold text-blueGray-500 rounded justify-between"
                :key="`${action.label}-${action.type}`"
                @click="handleAction(action, $event)"
              >
                {{ action.label }}
                <i :class="action.icon" class="mx-2" />
              </div>
            </draggable>
          </div>
        </div>
      </div>
      <div>
        <div
          class="px-4 py-4 border-r overflow-y-scroll mx-auto"
          style="width: 550px; height: calc(100vh - 128px)"
        >
          <draggable
            class="list-group"
            tag="ul"
            v-model="items"
            v-bind="dragOptions"
            group="action"
            @start="drag = true"
            @end="handleEnd"
            @change="handleDrop"
          >
            <transition-group
              type="transition"
              :name="!drag ? 'flip-list' : null"
            >
              <li
                class="list-group-item px-4 py-2 bg-white my-2 relative"
                :class="{
                  'border-l-4 border-indigo-700': selectedIndex == index,
                  'border-l-4 border-white': selectedIndex == index
                }"
                v-for="(item, index) in items"
                :key="`${index}-items`"
                @click="activateItem(index, $event)"
              >
                <el-popconfirm
                  :title="$t('Are you sure to delete this?')"
                  @confirm="removeItem(index)"
                >
                  <i
                    slot="reference"
                    class="far fa-trash-alt text-rose-500 absolute text-sm right-2 top-3"
                  />
                </el-popconfirm>
                <span class="font-semibold">
                  <i :class="actionIcon[item.custom_data.type]" class="mx-2" />
                  {{ item.name }}
                </span>
              </li>
            </transition-group>
          </draggable>
        </div>
      </div>
      <div class="w-full">
        <CheckListItem
          v-if="activeItem"
          @markEdited="markEdited"
          :item="activeItem"
          :index="selectedIndex"
          :actions="actions"
        />
      </div>
    </div>

    <div
      class="fixed left-0 bottom-0 border-t bg-white px-5 py-4 flex justify-between items-center"
      :class="{
        'bg-rose-100': edited,
        'ml-64 checklistFooter-normal': showSideNav,
        'md:left-10 checklistFooter-collapsed': !showSideNav
      }"
    >
      <div class="flex items-center">
        <h2
          class="font-semibold text-blueGray-500 ml-4"
          v-show="checkList.name"
        >
          {{ checkList.name }} (
          <span
            class="text-teal-500 border-b-4 border-teal-500 cursor-pointer"
            @click="editName = true"
          >
            {{ $t("configuration") }}
          </span>
          /
          <span
            @click="handleDelete"
            class="text-rose-500 border-b-4 border-rose-500 cursor-pointer"
          >
            {{ $t("delete checklist") }} </span
          >)
        </h2>
      </div>
      <div>
        <span class="text-rose-600 font-semibold" v-if="edited">
          {{ $t("you have unsaved progress") }}
        </span>
        <button
          @click="handleSave"
          class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs ml-4 px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        >
          {{ $t("save") }}
        </button>
        <button
          v-show="checkList.name"
          @click="togglePublish"
          class="text-white active:bg-emerald-600 font-bold uppercase text-xs ml-4 px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          :class="!checkList.published ? 'bg-indigo-500' : 'bg-red-500'"
        >
          <span v-if="!checkList.published">
            {{ $t("publish") }}
          </span>
          <span v-if="checkList.published">
            {{ $t("unpublish") }}
          </span>
        </button>
      </div>
    </div>
    <el-drawer
      :with-header="false"
      :visible.sync="editName"
      direction="rtl"
      size="550px"
    >
      <div class="bg-gray-100 h-screen overflow-auto py-1">
        <CheckListTitle
          v-if="editName"
          :name="checkList.name"
          @cancelNameChange="cancelNameChange"
          :schedules="checkList.checklist_schedules"
          :checklistTypes="options['checklist']"
          :type_id="checkList.type_id"
          :slackAlertURL="
            checkList.custom_data.slackAlertUrl
              ? checkList.custom_data.slackAlertUrl
              : ''
          "
        />
        <div class="my-4" />
        <AssignTypes
          :title="$t('assign groups')"
          :items="$store.state.groups.groups"
          :currentItems="checkList.groups"
          assignType="groups"
        />
        <AssignTypes
          :title="$t('assign hubs')"
          :items="$store.state.hubs.hubs"
          :currentItems="checkList.hubs"
          assignType="hubs"
        />
        <AssignTypes
          :title="$t('assign asset types')"
          :items="$store.state.typeDefinitions.bike"
          :currentItems="checkList.bike_types"
          assignType="bike_types"
        />
        <CascadeSelectorAssetAssign
          title="assign assets"
          :options="cascadeOptions"
          @change="handleItemChange"
          v-model="cas"
          :cas="cas"
        />
        <CascadeSelectorAssetAssign
          title="assign asset start checklist"
          :options="cascadeOptions"
          @change="handleStartCasChange"
          v-model="startCas"
          :cas="startCas"
        />
        <CascadeSelectorAssetAssign
          title="assign asset end checklist"
          :options="cascadeOptions"
          @change="handleEndCasChange"
          v-model="endCas"
          :cas="endCas"
        />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapState } from "vuex";
import draggable from "vuedraggable";
import CheckListTitle from "../components/checklist/Title.vue";
import CheckListItem from "../components/checklist/CheckListItem.vue";
import AssignTypes from "../components/checklist/AssignTypes.vue";
import CascadeSelectorAssetAssign from "../components/checklist/CascadeSelectorAssetAssign.vue";

export default {
  name: "CheckListDetail",
  components: {
    draggable,
    CascadeSelectorAssetAssign,
    CheckListTitle,
    CheckListItem,
    AssignTypes
  },
  data() {
    return {
      cascadeOptions: [],
      cas: [],
      startCas: [],
      endCas: [],
      edited: false,
      editName: false,
      checkListName: "",
      selectedIndex: null,
      sortable: null,
      drag: false,
      actions: [
        {
          type: "heading",
          label: "heading",
          icon: "fas fa-heading",
          custom_data: {
            description: "",
            canAttachMedia: false
          }
        },
        {
          type: "option",
          label: "option",
          icon: "far fa-dot-circle",
          custom_data: {
            description: "",
            required: true,
            options: [
              {
                value: "option 1",
                solutions: [],
                canAttachMedia: false,
                showSolutions: false
              }
            ]
          }
        },
        {
          type: "yesno",
          label: "yes no",
          icon: "fas fa-check-double",
          custom_data: {
            description: "",
            required: true,
            options: [
              {
                value: "yes",
                solutions: [],
                canAttachMedia: false,
                showSolutions: false
              },
              {
                value: "no",
                solutions: [],
                canAttachMedia: false,
                showSolutions: false
              }
            ]
          }
        },
        {
          type: "checkbox",
          label: "checkbox",
          icon: "far fa-square",
          custom_data: {
            description: "",
            required: true,
            options: [
              {
                value: "option 1",
                solutions: [],
                canAttachMedia: false,
                showSolutions: false
              }
            ]
          }
        },
        {
          type: "check",
          label: "check off",
          icon: "fas fa-check",
          custom_data: {
            description: "",
            solutions: [],
            canAttachMedia: false,
            showSolutions: false,
            required: true
          }
        },
        {
          type: "number",
          label: "number",
          icon: "fas fa-hashtag",
          custom_data: {
            description: "",
            min: null,
            max: null,
            unit: null,
            solutions: [],
            canAttachMedia: false,
            showSolutions: false,
            required: true
          }
        },
        {
          type: "signature",
          label: "signature",
          icon: "fas fa-signature",
          custom_data: {
            description: "",
            required: true
          }
        },
        {
          type: "temp",
          label: "temp",
          icon: "fas fa-thermometer-quarter",
          custom_data: {
            description: "",
            min: null,
            max: null,
            unit: "°C",
            canAttachMedia: false,
            showSolutions: false,
            required: true
          }
        },
        {
          type: "answer",
          label: "answer",
          icon: "fas fa-voicemail",
          custom_data: {
            description: "",
            hasScanner: false,
            canAttachMedia: false,
            showSolutions: false,
            required: true
          }
        },
        // {
        //   type: "input",
        //   label: "input",
        //   icon: "fas fa-font",
        //   custom_data: {
        //     hasScanner: false,
        //     description: "",
        //     canAttachMedia: false,
        //     showSolutions: false,
        //     required: true
        //   }
        // },
        {
          type: "dateTime",
          label: "date time",
          icon: "fas fa-calendar-day",
          custom_data: {
            dateOnly: false,
            description: "",
            canAttachMedia: false,
            showSolutions: false,
            required: true
          }
        }
      ],
      items: []
    };
  },
  computed: {
    ...mapState("checkLists", ["checkList"]),
    ...mapState("bikes", ["bikes"]),
    ...mapState("checkListItems", ["checkListItems"]),
    // cascadeOptions() {
    //   const groupByTypes = this.bikes.reduce((accu, b) => {
    //     const type_id = b.type_id ? b.type_id : -1;
    //     if (!accu[type_id]) {
    //       accu[type_id] = {
    //         label: b?.type?.name ? b?.type?.name : "-",
    //         value: type_id,
    //         children: []
    //       };
    //     }
    //     accu[type_id].children.push(b);
    //     return accu;
    //   }, {});
    //   return Object.values(groupByTypes).reduce((accu, bType) => {
    //     accu.push({
    //       label: bType.label,
    //       value: bType.value,
    //       children: bType.children.map(b => ({ value: b.id, label: b.name }))
    //     });
    //     return accu;
    //   }, []);
    // },
    options() {
      return {
        checklist: this.$store.state.typeDefinitions.checklist
      };
    },
    actionIcon() {
      return this.actions.reduce((accu, action) => {
        accu[action.type] = action.icon;
        return accu;
      }, {});
    },
    showSideNav() {
      return this.$store.state?.accounts?.user?.info?.showSideNav;
    },
    activeItem() {
      return this.selectedIndex != null ? this.items[this.selectedIndex] : null;
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  watch: {
    checkList() {
      this.checkListName = this.checkList.name;
      this.cas = this.checkList.bikes.map(b => {
        const type_id = b.type_id ? b.type_id : -1;
        return [type_id, b.id];
      });
    },
    checkListItems() {
      this.items = JSON.parse(JSON.stringify(this.checkListItems));
    }
  },
  methods: {
    setBikeData() {
      const groupByTypes = this.bikes.reduce((accu, b) => {
        const type_id = b.type_id ? b.type_id : -1;
        if (!accu[type_id]) {
          accu[type_id] = {
            label: b?.type?.name ? b?.type?.name : "-",
            value: type_id,
            children: []
          };
        }
        accu[type_id].children.push(b);
        return accu;
      }, {});
      this.cascadeOptions = Object.values(groupByTypes).reduce(
        (accu, bType) => {
          accu.push({
            label: bType.label,
            value: bType.value,
            children: bType.children.map(b => ({
              value: b.id,
              label: b.name
            }))
          });
          return accu;
        },
        []
      );
      this.startCas = this.bikes.reduce((accu, b) => {
        const type_id = b.type_id ? b.type_id : -1;
        if (b.start_checklist_id == this.$route.params.id) {
          accu.push([type_id, b.id]);
        }
        return accu;
      }, []);
      this.endCas = this.bikes.reduce((accu, b) => {
        const type_id = b.type_id ? b.type_id : -1;
        if (b.end_checklist_id == this.$route.params.id) {
          accu.push([type_id, b.id]);
        }
        return accu;
      }, []);
    },
    handleItemChange() {
      this.$store.dispatch("checkLists/updateCheckList", {
        check_list: {
          id: this.$route.params.id,
          bikes: this.cas.map(item => item[item.length - 1])
        }
      });
    },
    handleStartCasChange() {
      this.handleStartEndCasChange(
        "BATCH_ASSIGN_START_CHECKLIST",
        "start_checklist_id",
        this.startCas
      );
    },
    handleEndCasChange() {
      this.handleStartEndCasChange(
        "BATCH_ASSIGN_END_CHECKLIST",
        "end_checklist_id",
        this.endCas
      );
    },
    handleStartEndCasChange(action, checklist_type, startEndCas) {
      let cas = startEndCas.map(item => item[item.length - 1]);
      let bikes = this.bikes.reduce((accu, b) => {
        // only post what is needed
        if (b[checklist_type] == this.$route.params.id && !cas.includes(b.id)) {
          accu[b.id] = false;
        }
        if (cas.includes(b.id) && b[checklist_type] != this.$route.params.id) {
          accu[b.id] = true;
        }
        return accu;
      }, {});
      this.$store.dispatch("checkLists/updateCheckList", {
        check_list: {
          id: this.$route.params.id,
          action: action,
          bikes //
        },
        cb: () => {
          this.$store.dispatch("bikes/getAllBikes", { noFilter: true });
        }
      });
    },
    cancelNameChange() {
      this.editName = false;
    },
    togglePublish() {
      this.handleSave();
      this.$store.dispatch("checkLists/updateCheckList", {
        check_list: {
          id: this.$route.params.id,
          published: !this.checkList.published
        }
      });
    },
    markEdited() {
      this.edited = true;
    },
    onMove({ relatedContext }) {
      const relatedElement = relatedContext.element;
      // const draggedElement = draggedContext.element;
      if (relatedElement?.name) return true;
      return false;
    },
    handleDelete() {
      const deleteString = `${this.$t("Confirm delete checklist")}: ${
        this.checkListName
      }`;
      this.$confirm(deleteString).then(() => {
        this.$store.dispatch("checkLists/deleteCheckList", {
          check_list: this.checkList,
          cb: () => this.$router.push({ name: "CheckList" })
        });
      });
    },
    handleSave() {
      const formData = new FormData();
      this.items.forEach((item, i) => {
        const checklistItem = { ...item, checklist_id: this.$route.params.id };
        formData.append(
          `check_list_items[${i}][data]`,
          JSON.stringify(checklistItem)
        );
        if (item.files) {
          Array.from(item.files).forEach(file => {
            formData.append(`check_list_items[${i}][files][]`, file);
          });
        }
      });
      this.$store.dispatch("checkListItems/upsertCheckListItems", {
        check_list_id: this.$route.params.id,
        check_list_items: formData,
        cb: () => (this.edited = false)
      });
    },
    getNewItem({ label, type, custom_data }) {
      this.markEdited();
      return JSON.parse(
        JSON.stringify({ name: label, custom_data: { ...custom_data, type } })
      );
    },
    handleDrop({ added }) {
      if (added) {
        const item = this.getNewItem(added.element);
        this.items.splice(added.newIndex, 1, item);
        this.selectedIndex = added.newIndex;
      }
    },
    activateItem(index, evt) {
      evt.stopPropagation();
      this.selectedIndex = this.selectedIndex == index ? null : index;
    },
    handleEnd(evt) {
      this.drag = false;
      this.selectedIndex = evt.newIndex;
      this.markEdited();
    },
    removeItem(i) {
      if (this.items[i]?.id) {
        this.$store.dispatch("checkListItems/deleteCheckListItem", {
          id: this.items[i]?.id
        });
      }
      this.items.splice(i, 1);
      this.selectedIndex = null;
    },
    handleAction(action, evt) {
      evt.preventDefault();
      evt.stopPropagation();
      const item = this.getNewItem(action);
      if (this.selectedIndex != null) {
        this.items.splice(this.selectedIndex + 1, 0, item);
        this.selectedIndex = this.selectedIndex + 1;
      } else {
        this.items.push(item);
        this.selectedIndex = this.items.length - 1;
      }
    }
  },
  created() {
    if (this.$route.params.id != "new") {
      this.$store.dispatch("checkLists/getCheckList", {
        id: this.$route.params.id
      });
      this.$store.dispatch(
        "checkListItems/getAllCheckListItems",
        this.$route.params.id
      );
      this.$store.dispatch("groups/getGroups");
      this.$store.dispatch("hubs/getAllHubs");
      this.$store.dispatch("bikes/getAllBikes", {
        noFilter: true,
        cb: this.setBikeData
      });
      this.$store.dispatch("typeDefinitions/getAllCheckListDefinitions");
      this.$store.dispatch("typeDefinitions/getAllBikeDefinitions");
    }
  },
  beforeRouteLeave(to, from, next) {
    let answer = true;
    if (this.edited) {
      answer = window.confirm(
        this.$t("Do you really want to leave? you have unsaved changes!")
      );
    }
    if (answer) {
      next();
    } else {
      next(false);
    }
  }
};
</script>

<style>
.checklistFooter-normal {
  width: calc(100vw - 16rem);
}
.checklistFooter-collapsed {
  width: calc(100vw - 2.5rem);
}
.button {
  margin-top: 35px;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
  padding: 5px 10px;
  border: 4px dashed #91bbcf;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>
