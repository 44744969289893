<template>
  <div class="grid grid-cols-3 gap-4 mb-4">
    <div class="flex flex-col">
      <label class="text-semibold text-xs uppercase">{{ $t("min") }}</label>
      <input
        v-model.number="item.custom_data.min"
        class="border px-4 py-2"
        type="number"
        @input="$emit('markEdited')"
      />
    </div>
    <div class="flex flex-col">
      <label class="text-semibold text-xs uppercase">{{ $t("max") }}</label>
      <input
        v-model.number="item.custom_data.max"
        class="border px-4 py-2"
        type="number"
        @input="$emit('markEdited')"
      />
    </div>
    <div class="flex flex-col">
      <label class="text-semibold text-xs uppercase">{{ $t("unit") }}</label>
      <input
        v-model="item.custom_data.unit"
        class="border px-4 py-2"
        type="text"
        @input="$emit('markEdited')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "ChecklistNumber",
  props: {
    item: {
      type: Object,
      required: true
    }
  }
};
</script>
