<template>
  <div
    @click="handleClick($event)"
    class="w-full overflow-y-scroll relative"
    style="height: calc(100vh - 128px)"
  >
    <div class="mx-auto px-4 py-4 border-b top-0 sticky bg-blueGray-100 z-20">
      <h3
        class="font-semibold uppercase text-xs text-rose-50 absolute top-3 right-0 bg-gray-400 px-4 py-2"
      >
        {{ $t("Preview") }}
      </h3>
      <p class="text-xs mt-2 mb-4 text-blueGray-700">
        {{
          $t(
            "This is a preview of the component without any actual functionality"
          )
        }}
      </p>
      <PreviewComponent
        :key="`${index}-${item.name}`"
        style="max-width: 30rem"
        :item="item"
      />
    </div>
    <div class="py-4 px-4">
      <div class="grid grid-cols-3 gap-4 mb-2">
        <div class="col-span-2">
          <label class="text-semibold text-xs uppercase">
            {{ $t("name") }}
          </label>
          <input
            class="border px-4 py-2 w-full"
            :placeholder="$t('name')"
            v-model="item.name"
            @input="$emit('markEdited')"
          />
        </div>
        <div>
          <label class="text-semibold text-xs uppercase">
            {{ $t("component") }}
          </label>
          <el-select
            @change="handleTypeChange"
            v-model="item.custom_data.type"
            placeholder="Select"
            class="w-full"
          >
            <el-option
              v-for="action in actions"
              :key="action.type"
              :value="action.type"
              class="flex justify-between items-center"
            >
              {{ action.label }}
              <i :class="action.icon" class="mx-2" />
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="mb-2">
        <label class="text-semibold text-xs uppercase">
          {{ $t("description") }}
        </label>
        <textarea
          class="px-3 py-2 border w-full"
          v-model="item.custom_data.description"
          :placeholder="$t('description')"
          @input="$emit('markEdited')"
        />
      </div>
      <RadioCheckBox
        v-if="
          item.custom_data.type == 'radio' ||
          item.custom_data.type == 'option' ||
          item.custom_data.type == 'yesno' ||
          item.custom_data.type == 'checkbox'
        "
        @markEdited="$emit('markEdited')"
        :custom_data="item.custom_data"
      />
      <ChecklistNumber
        v-if="
          item.custom_data.type == 'number' || item.custom_data.type == 'temp'
        "
        :item="item"
        @markEdited="$emit('markEdited')"
      />
      <Switches
        :custom_data="item.custom_data"
        @markEdited="$emit('markEdited')"
      />
      <UploadMedia :item="item" @markEdited="$emit('markEdited')" />
    </div>
    <!-- <pre>
    {{ item }}
    </pre> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import UploadMedia from "./UploadMedia.vue";
import ChecklistNumber from "./ChecklistNumber.vue";
import PreviewComponent from "./PreviewComponent.vue";
import Switches from "./Switches.vue";
import RadioCheckBox from "./RadioCheckBox.vue";

export default {
  name: "CheckListItem",
  props: {
    index: {
      type: Number,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    actions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      drag: false
    };
  },
  components: {
    RadioCheckBox,
    UploadMedia,
    ChecklistNumber,
    PreviewComponent,
    Switches
  },
  computed: {
    ...mapState("checkLists", ["checkList"]),
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  methods: {
    handleTypeChange(newType) {
      this.$emit("markEdited");
      let { custom_data } = this.actions.find(action => action.type == newType);
      this.$set(
        this.item,
        "custom_data",
        JSON.parse(JSON.stringify({ ...custom_data, type: newType }))
      );
    },
    handleClick(evt) {
      evt.stopPropagation();
    }
  }
};
</script>
