<template>
  <div class="w-full pt-4">
    <h3 class="px-4 text-xs font-semibold uppercase text-gray-500 mb-1">
      {{ $t("checklist title") }}
    </h3>
    <div class="px-6 py-2 border-b flex">
      <input
        class="flex border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-800 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        v-model="checkListName"
        type="text"
        name=""
        id=""
      />
      <button
        class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs ml-4 px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        type="button"
        @click="handleSaveName"
      >
        {{ $t("save") }}
      </button>
      <button class="ml-2 focus:outline-none">
        <span @click="$emit('cancelNameChange')" class="border-b-4">
          {{ $t("cancel") }}
        </span>
      </button>
    </div>
    <div class="px-4 pb-2 border-b">
      <h3 class="text-xs font-semibold uppercase text-gray-500 mb-1 mt-4">
        {{ $t("type") }}
      </h3>
      <div class="px-2">
        <el-select
          @change="handleCheckListTypeChange"
          clearable
          class="w-full"
          v-model="checkListType"
          placeholder="Select"
        >
          <el-option
            v-for="item in checklistTypes"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </div>
    </div>
    <div class="px-4 pb-2 border-b">
      <h3 class="text-xs font-semibold uppercase text-gray-500 mb-1 mt-4">
        {{ $t("slack alert webhook") }}
      </h3>
      <div class="px-3 py-2 flex">
        <input
          class="flex border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-800 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          v-model="slackAlertUrl"
          type="text"
          name=""
          id=""
        />
        <button
          class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs ml-4 px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
          @click="handleSlackAlert"
        >
          {{ $t("save") }}
        </button>
      </div>
    </div>
    <ScheduleChecklist :schedules="schedules" />
  </div>
</template>

<script>
// import moment from "moment";
import formatting from "../../mixins/formatting";
import ScheduleChecklist from "./ScheduleChecklist.vue";
export default {
  name: "CheckListTitle",
  mixins: [formatting],
  components: { ScheduleChecklist },
  props: {
    name: {
      type: String,
      required: true
    },
    slackAlertURL: {
      type: String,
      required: true
    },
    type_id: {
      type: Number,
      required: true
    },
    checklistTypes: {
      type: Array,
      required: true
    },
    schedules: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      editMode: false,
      checkListName: "",
      checkListType: "",
      slackAlertUrl: ""
      // dateVal: "",
      // timeVal: "",
      // scheduleType: "one-time",
      // options: ["one-time", "daily", "weekly", "monthly"],
      // pickerOptions: {
      //   disabledDate(time) {
      //     return time.getTime() < moment(Date.now()).startOf("day");
      //   }
      // }
    };
  },
  methods: {
    removeSchedule(schedule) {
      this.$store.dispatch("checkLists/deleteSchedule", { schedule });
    },
    handleCheckListTypeChange() {
      this.handleSave({
        check_list: {
          id: this.$route.params.id,
          type_id: this.checkListType
        }
      });
    },
    // handleSaveSchedule() {
    //   const type =
    //     this.scheduleType == "one-time" ? "single" : this.scheduleType;
    //   this.$store.dispatch("checkLists/createSchedule", {
    //     check_list_schedule: {
    //       type,
    //       scheduled_at: this.timeVal,
    //       start_date: moment(this.dateVal).format("YYYY-MM-DDT00:00:00"),
    //       end_date: moment(this.dateVal).format("YYYY-MM-DDT00:00:00"),
    //       occur_at: "",
    //       checklist_id: this.$route.params.id
    //     }
    //   });
    //   // this.handleSave(,
    //   //   cb: () => this.$emit("cancelNameChange")
    //   // });
    // },
    handleSlackAlert() {
      this.$store.dispatch("checkLists/updateCheckList", {
        check_list: {
          id: this.$route.params.id,
          custom_data: {
            slackAlertUrl: this.slackAlertUrl
          }
        }
      });
    },
    handleSaveName() {
      this.handleSave({
        check_list: {
          id: this.$route.params.id,
          name: this.checkListName
        },
        cb: () => this.$emit("cancelNameChange")
      });
    },
    handleSave(payload) {
      this.$store.dispatch("checkLists/updateCheckList", payload);
    }
  },
  created() {
    this.checkListName = this.name;
    this.checkListType = this.type_id;
    this.slackAlertUrl = this.slackAlertURL;
  }
};
</script>
