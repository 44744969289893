<template>
  <div class="px-4">
    <h3
      class="pt-4 cursor-pointer flex justify-between items-center font-semibold text-blueGray-500 uppercase text-xs"
    >
      <span> {{ title }} </span>
    </h3>
    <el-select
      @change="handleItemChange"
      filterable
      class="w-full"
      v-model="selectedItems"
      clearable
      multiple
      placeholder="Select"
    >
      <el-option
        v-for="item in items"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: "AssignTypes",
  props: {
    title: {
      type: String,
      require: true
    },
    items: {
      type: Array,
      require: true
    },
    currentItems: {
      type: Array,
      require: true
    },
    assignType: {
      type: String,
      require: true
    },
    noBt: {
      type: Boolean,
      require: false
    }
  },
  data() {
    return {
      selectedItems: null
    };
  },
  methods: {
    handleItemChange() {
      this.$store.dispatch("checkLists/updateCheckList", {
        check_list: {
          id: this.$route.params.id,
          [this.assignType]: this.selectedItems
        }
      });
    }
  },
  created() {
    this.selectedItems = this.currentItems.map(item => item.id);
  }
};
</script>
