<template>
  <div class="flex">
    <div v-if="custom_data.type == 'answer'" class="text-xs uppercase mr-4">
      <span class="mr-2">{{ $t("add QR scanner") }}?</span>
      <el-switch
        v-model="custom_data.hasScanner"
        @change="$emit('markEdited')"
        active-color="#13ce66"
        inactive-color="#ff4949"
      />
    </div>
    <div
      v-if="custom_data.type != 'heading' && custom_data.type != 'signature'"
      class="text-xs uppercase mr-4"
    >
      <span class="mr-2">{{ $t("can attach media") }}?</span>
      <el-switch
        v-model="custom_data.canAttachMedia"
        @change="$emit('markEdited')"
        active-color="#13ce66"
        inactive-color="#ff4949"
      />
    </div>
    <div
      v-if="
        custom_data.type != 'heading' &&
        custom_data.type != 'signature' &&
        custom_data.type != 'dateTime'
      "
      class="text-xs uppercase mr-4"
    >
      <span class="mr-2">{{ $t("attach solution") }}?</span>
      <el-switch
        v-model="custom_data.showSolutions"
        @change="$emit('markEdited')"
        active-color="#13ce66"
        inactive-color="#ff4949"
      />
    </div>
    <div v-if="custom_data.type == 'dateTime'" class="text-xs uppercase mr-4">
      <span class="mr-2">{{ $t("Date only") }}?</span>
      <el-switch
        v-model="custom_data.dateOnly"
        @change="$emit('markEdited')"
        active-color="#13ce66"
        inactive-color="#ff4949"
      />
    </div>
    <div v-if="custom_data.type != 'heading'" class="text-xs uppercase">
      <span class="mr-2">{{ $t("required") }}?</span>
      <el-switch
        v-model="custom_data.required"
        @change="$emit('markEdited')"
        active-color="#13ce66"
        inactive-color="#ff4949"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Switches",
  props: {
    custom_data: {
      type: Object,
      require: true
    }
  }
};
</script>
